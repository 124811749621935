<template>
  <section class="invoice-preview-wrapper">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="orderData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-invoice-list' }"
        >
          Invoice List
        </b-link>
        for other invoices.
      </div>
    </b-alert>

    <b-row
      v-if="orderData"
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12">
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="
                d-flex
                justify-content-between
                flex-md-row flex-column
                invoice-spacing
                mt-0
              "
            >
              <!-- Header: Left Content -->
              <div>
                <h6 class="mb-2">
                  Invoice To:
                </h6>
                <p class="card-text mb-25">
                  <strong>Branch:</strong> {{ orderData.branchId ? mapBranch(orderData.branchId) : 'None' }}
                  {{ orderData.country }}
                </p>
                <h6 class="mb-25">
                  <strong>Customer name:</strong> {{ orderData.customerName || 'None' }}
                </h6>
                <p class="card-text mb-25">
                  <!-- {{ orderData.client.company }} -->
                </p>

                <p class="card-text mb-25">
                  <strong>Shipping address:</strong> {{ orderData.shippingAddress || 'None' }}
                </p>
                <p class="card-text mb-0">
                  <strong>Note:</strong> {{ orderData.note || 'None' }}
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Order
                  <span class="invoice-number">#{{ orderData.cukcukOrderId }}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Date Issued:
                  </p>
                  <p class="invoice-date">
                    {{ formatDate(orderData.createdAt) }}
                  </p>
                </div>
                <div
                  v-if="false"
                  class="invoice-date-wrapper"
                >
                  <p class="invoice-date-title">
                    Due Date:
                  </p>
                  <p class="invoice-date">
                    {{ formatDate(orderData.updatedAt) }}
                  </p>
                </div>
                <div
                  class="invoice-date-wrapper"
                >
                  <p class="invoice-date-title">
                    Status:
                  </p>
                  <p class="invoice-date">
                    {{ mapStatus(orderData.paymentStatus) }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="orderData.orderItems"
            :fields="['description', 'price', 'quantity', 'total']"
          >
            <template #cell(description)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.itemName }}
              </b-card-text>
            </template>
            <template #cell(price)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ "VND" + " " + (data.item.price||'0') }}
              </b-card-text>
            </template>
            <template #cell(total)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{
                  "VND" +
                    " " +
                    data.item.price * data.item.quantity
                }}
              </b-card-text>
            </template>
          </b-table-lite>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <!-- <span class="font-weight-bold">Salesperson:</span> -->
                  <!-- <span class="ml-75">Alfie Solomons</span> -->
                </b-card-text>
              </b-col>
              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <!-- <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Subtotal:
                    </p>
                    <p class="invoice-total-amount">
                      {{
                        orderData.subTotal
                      }}
                    </p>
                  </div> -->
                  <!-- <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Discount:
                    </p>
                    <p class="invoice-total-amount">
                      {{
                        (orderData.itemDiscount + orderData.discount)
                      }}
                    </p>
                  </div> -->
                  <!-- <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Tax:
                    </p>
                    <p class="invoice-total-amount">
                      {{ orderData.taxesConfig.taxLocal }}
                    </p>
                  </div> -->
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total:
                    </p>
                    <p class="invoice-total-amount">

                      {{
                        "VND " + (orderData.totalAmount||'0')
                      }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BAlert,
    BLink,
  },
  computed: {
    ...mapGetters('cukcuk', ['paymentStatus']),

  },
  methods: {
    formatDate(time) {
      return moment(time).add(7, 'hours').format('HH:mm:ss DD/MM/YYYY')
    },
    mapStatus(id) {
      return this.paymentStatus.find(e => e.value === id).label
    },
    mapBranch(data) {
      const foundBranch = this.branchOptions.find(x => x.value === data)
      if (foundBranch) {
        return foundBranch.label
      }
      return data

      // return data ? this.branchOptions.find(x => x.value === data).label : 'None'
      // return data
    },
  },
  setup() {
    const orderData = ref(null)
    const paymentDetails = ref({})
    const branchOptions = ref([])
    // Invoice Description
    // ? Your real data will contain this information
    const fetchBranch = () => {
      store.dispatch('cukcuk/fetchCukCukBranch').then(res => {
        if (res.body.Data.length > 0) {
          branchOptions.value = res.body.Data.map(x => ({
            label: x.Name,
            value: x.Id,
          }))
        }
      })
    }
    fetchBranch()
    store
      .dispatch('order/fetchOrder', { id: router.currentRoute.params.id })
      .then(response => {
        orderData.value = response.body
        // paymentDetails.value = response.data.paymentDetails
      })
      .catch(error => {
        console.log(error)
        orderData.value = undefined
      })
    const printInvoice = () => {
      window.print()
    }

    return {
      orderData,
      paymentDetails,
      printInvoice,
      branchOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
